<script setup>
 // Props
 const props = defineProps({
  page: {
   type: Object,
   required: true,
  },
 });

 const { page } = toRefs(props);
</script>

<!-- These "templates" need to return a single root node -->
<!-- Otherwise Vue get's mad -->
<template>
 <div>
  <main id="main" :key="'building' + page.content.id" class="mess-page buildings" tabindex="-1">
   <TemplatesBuildingsHeader v-if="page.content.id" :page="page" />
   <TemplatesBuildingsDetails v-if="page.content.id" :building="page" />
   <MessRenderContent :page="page" />
  </main>
 </div>
</template>
